.navbarContainer {
    height: 100vh;
    flex: 0 0 170px;
}

/*Flexbox items cannot have position fixed, so we have to make a fixed wrapper*/
.navbarContainer > div {
    width: 170px;
    height: 100vh;
    border-right: 1px solid #C2C2C2;
    box-shadow: 1px 0 3px 0 #A5A4A4;
    position: fixed;
    background: #FFFFFF;
}

.navbarHeader {
    display: flex;
    flex-direction: column;
    height: 150px;
    border-bottom: 1px solid #C2C2C2;
    overflow: hidden;
}

.navbarHeaderLogo {
    flex: 3;
    margin: 10px
}

.navbarHeaderLogo > img {
    width: 140px;
}

.navbarHeaderText {
    flex: 1;
    margin: 0 10px;
    font-weight: bold;
}

.navbarHeaderUsername {
    flex: 3;
    margin: 5px 10px;
}

.navbarHeaderMobile {
    display: none;
}

.navbarSection {
    display: block;
    background: #FFFFFF;
}

.navbarSection > ul {
    list-style-type: None;
    margin: 0;
    padding: 0;
}

.navbarSection > ul > li > svg {
    width: 20px !important;
    height: 20px !important;
}

.navbarSection > ul > li > span {
    padding-left: 12px;
}

.navbarItem {
    padding: 15px 0 15px 10px;
    border-left: 5px solid transparent;
    border-bottom: 1px solid #C2C2C2;
}

.navbarItemSelected{
    padding: 15px 0 15px 10px;
    border-left: 5px solid #9A6828;
    font-weight: bold;
    background: #f2f2f2;
    color: #9A6828;
    cursor: pointer;
    border-bottom: 1px solid #C2C2C2;
}

.navbarItem:hover {
    border-left: 5px solid #9A6828;
    font-weight: bold;
    background: #f2f2f2;
    color: #9A6828;
    cursor: pointer;
}

@media screen and (max-width: 640px) {
    .navbarContainer {
        width: 100vw !important;
        height: initial !important;
        flex: 0 0 50px;
    }

    .navbarContainer > div {
        width: 100vw;
        height: 50px;
        border-right: initial !important;
        box-shadow: 1px 0 3px 0 #A5A4A4;
        position: fixed;
    }

    .navbarHeader {
        height: 50px;
        flex-direction: initial;
    }

    .navbarHeaderLogo > img {
        width: 87px;
        height: 40px;
    }

    .navbarHeaderLogo {
        flex: 3;
        margin: 5px 10px;
    }

    .navbarHeaderText{
        display: none;
    }

    .navbarHeaderUsername {
        display: none;
    }

    .navbarHeaderMobile {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background: #C0853F;
        color: #FFFFFF;
    }

    .navbarSection {
        display: none;
    }

    .navbarHeaderMobile:active {
        background: #775227;
    }

}

/*Referencias*/
/*1. https://stackoverflow.com/questions/29036231/how-can-i-have-a-position-fixed-behaviour-for-a-flexbox-sized-element*/
/*2. https://stackoverflow.com/questions/31722839/flex-layout-with-fixed-position-no-scrolling-sidebar*/
