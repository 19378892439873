.isAuthContainer {
    width: 100vw;
    position: absolute;
    display: flex;
}

.isAuthBody {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 640px) {
    .isAuthContainer {
        flex-direction: column;
        height: 100vh;
    }

}

/*Referencias*/
/*1. https://developer.mozilla.org/en-US/docs/Learn/CSS/CSS_layout/Flexbox*/
