.authBackground {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(#FFFFFF, #E8A451);
}

.authlogo {
    display: block;
    margin: 10px auto;
    padding: 10px;
    height: 70px;
}

.authContainer {
    width: 100vw;
    position: absolute;
    top: 20%;
}

.authLoginBox {
    width: 600px;
    height: 380px;
    margin: 10px auto;
    background: #FFFFFF;
    border-radius: 10px;
    transition: box-shadow 200ms linear;
}

.loginBoxHeader {
    padding: 0.5rem;
    text-align: center;
    font-weight: bold;
    color: #707070;
}

.loginBoxText {
    display: block;
    margin: 0.5rem;
    font-size: 1.25rem;
}

.loginBoxInput {
    margin: 1.25rem;
    padding: 0.5rem;
    font-size: 1.25rem;
}

.loginBoxButton {
    margin: 1rem 10rem;
    padding: 0.5rem;
    font-weight: bold;
    font-size: 1.5rem;
    color: #FFFFFF;
    background: #C0853F;
    border: 0;
    border-radius: 10px;
    transition: background 100ms ease-in-out;
}

.loginBoxButtonDisabled {
    margin: 1rem 10rem;
    padding: 0.5rem;
    font-weight: bold;
    font-size: 1.5rem;
    color: #FFFFFF;
    background: #C2C2C2;
    border: 0;
    border-radius: 10px;
}

.errorMsg {
    min-height: 20px;
    text-align: center;
    color: #FF0000;
    font-weight: 600;
}

.formContainer {
    display: flex;
    flex-direction: column;
}

.authLoginBox:hover {
    box-shadow: 0 5px 15px 0 #707070;
}

.loginBoxButton:hover {
    background: #97652C;
}

.loginBoxButton:active {
    background: #775227;
}

@media screen and (max-width: 640px) {
    .authlogo {
        width: 105px;
        height: 50px;
    }

    .authLoginBox {
        width: 300px;
    }

    .loginBoxButton {
        margin: 1rem 2rem;
        font-weight: bold;
        font-size: 1.5rem;
        color: #FFFFFF;
        background: #C0853F;
        border: 0;
        border-radius: 10px;
        transition: background 100ms ease-in-out;
    }

    .loginBoxButtonDisabled {
        margin: 1rem 2rem;
        font-weight: bold;
        font-size: 1.5rem;
        color: #FFFFFF;
        background: #C2C2C2;
        border: 0;
        border-radius: 10px;
    }
}

@media (max-height: 414px) and (max-width: 736px){
    .authContainer {
        top: 5%;
    }

    .authlogo {
        width: 85px;
        height: 41px;
    }

    .authLoginBox {
        width: 480px;
        height: 288px;
    }

    .loginBoxHeader {
        padding: 10px 0 0 0;
    }

    .loginBoxText {
        margin: 0;
        font-size: 1rem;
    }

    .loginBoxInput {
        margin: 0.75rem;
    }

    .loginBoxButton, .loginBoxButtonDisabled {
        margin: 1rem 5rem;
    }

}

/*Referencias*/
/*1. https://developer.mozilla.org/en-US/docs/Web/CSS/length*/
/*2. https://developer.mozilla.org/en-US/docs/Glossary/viewport*/
/*3. https://developer.mozilla.org/en-US/docs/Web/CSS/@media*/
/*4. https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img*/
/*5. https://developer.mozilla.org/en-US/docs/Web/CSS/transition*/