.viewSection {
    height: 100%;
    overflow: auto;
}

.viewSection > div { /*Esto permite "encerrar el contenido dentro de <section>"*/
    height: 500px;
    display: flex;
    flex-direction: column;
}

.viewModuleRow {
    display: flex;
}

.viewModule {
    height: 350px;
    margin: 10px;
    border: 1px solid #C0853F;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.viewModule > header {
    padding: 10px 0 10px 10px;
    background: #C0853F;
    color: #FFFFFF;
    font-weight: bold;
    display: flex;
}

.viewModule > header > span {
    padding: 2px 0;
}

.viewModule > header > button{
    margin: 0 10px 0 auto;
}

.viewModule > div {
    height: 100%;
    padding: 5px 10px;
}

.viewToolbar {
    min-height: 35px;
    padding: 10px;
    display: flex;
}

.toolbarButtonsContainer {
    margin-left: 25px;
    display: flex;
    flex-direction: row;
}

.toolbarButton {
    margin: 0 10px;
    border: 0;
    border-radius: 10px;
    padding: 7px 15px;
    display: block;
    color: #fff;
    transition: all 200ms ease-in-out;
}

.toolbarButton > span {
    padding-left: 10px;
    font-size: 18px;
    font-weight: bold;
}

.toolbarButton:hover {
    box-shadow: 0 1px 6px rgba(32,33,36,.5);
    transition: all 200ms ease-in-out;
}

.DownloadButton {
    background: #66CC00;
}

.DownloadButton:active {
    background: #408000;
}

.DescarteButton {
    background: #FF3333;
}

.DescarteButton:active {
    background: #E60000;
}

.DescarteButtonPressed {
    background: #990000;
}

.DescarteButtonPressed:active {
    background: #660000;
}


.viewSummary {
    overflow-y: auto;
}

.viewSummary > table{
    width: 100%;
    border-collapse: collapse;
}

.viewSummary > table > tbody > tr{
    border: 1px solid #C0853F;
    color: #573E22;;
}

.viewSummary > table > tbody > tr{
    height: 40px;
}

.viewSummaryCliente {
    background: linear-gradient(#FFF, #EBCEA6);
    font-weight: 600;
}

.viewSummaryCliente > td:first-child{
    padding-left: 5px;
}

.viewSummaryDepartamento {
    background: linear-gradient(#FFF, #EBCEA6);
}

.viewSummaryDepartamento > td:first-child {
    padding-left: 10px;
}

.viewSummaryEquipo > td:first-child {
    padding-left: 15px;
}

.viewSummarHidden {
    visibility: collapse;
}

.viewModal {

}

.modalExitButton {
    display: block;
    margin-left: auto;
}

.viewModalHeader {
    width: 100%;
    background: #C0853F;
    flex: 0 0 50px;
    display: flex;
    align-items: center;
}

.viewModalHeader > h2 {
    margin: unset;
    padding: 10px;
    color: #FFFFFF;
}

.viewModalHeader > button {
    margin: 0 10px 0 auto;
}

@media screen and (max-width: 640px) {
    .viewHeader {
        flex: 0 0 50px;
    }

    .viewModuleRow {
        flex-direction: column;
    }

    .viewTable > table {
        display: inline-block;
        text-align: center;
        overflow-x: auto;
    }

}
