.searchbarContainer {
    padding: 5px;
    flex: 1;
    display: flex;
    align-items: center;
    border: 1px #C2C2C2 solid;
    border-radius: 10px;
    transition: all 200ms ease-in-out;
}

.searchbarContainer > svg {
    margin: 0 10px;
    color: #808080;
}

.searchbarContainer > input {
    width: 100%;
    height: 100%;
    border: none;
}

.searchbarContainer:hover {
    box-shadow: 0 1px 6px rgba(32,33,36,.28);
    transition: all 200ms ease-in-out;
}

.searchbarContainer > input:focus {
    outline: none !important;
}
