.reportesContainer {
    height: 100%;
    overflow: auto;
}

.reportesContainer > div { /*Esto permite "encerrar el contenido dentro de <section>"*/
    height: 500px;
    display: flex;
    flex-direction: column;
}

.reportesToolbar {
    min-height: 35px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.selectorsRow {
    display: flex;
}

.selectorContainer {
    margin: 10px;
    border: 1px solid #C0853F;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.selectorHeader {
    padding: 10px 0 10px 10px;
    background: #C0853F;
    color: #FFFFFF;
    font-weight: bold;
    display: flex;
}

.selectorSelect {
    width: 100%;
    height: 100%;
    padding: 10px;
    border: none;
}
