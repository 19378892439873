.swmButton {
    margin: 0 10px;
    border: 0;
    border-radius: 10px;
    padding: 7px 15px;
    display: block;
    color: #fff;
    transition: all 200ms ease-in-out;
}

.swmButton > span {
    padding-left: 10px;
    font-size: 18px;
    font-weight: bold;
}

.buttonManual {
    background: #B300B3;
}

.buttonDisabled {
    background: #C2C2C2;
    color: #666666;
    font-weight: bolder;
}

.buttonManual:hover {
    box-shadow: 0 1px 6px rgba(32,33,36,.5);
    transition: all 200ms ease-in-out;
}

.buttonManual:active{
    background: #800080;
}

.noAnchorStyle {
    text-decoration: inherit;
    color: inherit;
}

