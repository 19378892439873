.error404Logo {
    width: 100px;
}

.error404Container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    top: 25%;
    position: relative;
}

.error404Emoji {
    padding-left: 25px;
    font-size: 5rem;
}