.emptyMessage {
    margin: 2rem;
    font-weight: bold;
    font-size: larger;
    text-align: center;
}

.viewTable {
    padding: 10px;
}

.viewTableTitle {
    text-align: center;
}

.viewTable > table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    text-align: center;
}


/*Ayuda a un Overflow-Y de la tabla más elegante*/
.viewTablePhantomHeader {
    height: 10px;
    margin: 0 9px;
    background: #FFFFFF;
    color: #FFFFFF;
    /*FIXME: Issue #25: position sticky no funciona en mobile*/
    position: sticky;
    top: 0;
}

.viewTableHeader {
    height: 40px;
    border: 1px solid #C0853F;
    color: #FFFFFF;
    letter-spacing: 1px;
}

.viewTableHeader > th {
    min-width: 150px;
    background: #C0853F;
    /*FIXME: Issue #25: position sticky no funciona en mobile*/
    position: sticky;
    top: 9px;
}

.viewTable > table > tbody > tr {
    border: 1px solid #C0853F;
}

.viewTable > table > tbody > tr > td {
    height: 60px;
}

.SwmTableRowDescartado {
    background-color: #FFE6E6;
}

.viewTableList {
    list-style-type: None;
    margin: 0;
    padding: 0;
}

.viewTableList > a:link, .viewTableList > a:visited{
    text-decoration: inherit;
    color: inherit;
}

.viewTableListDate {
    padding: 10px 0;
    margin: 10px;
}

.viewTableListItemDisabled {
    background: #C2C2C2;
    padding: 10px 0;
    margin: 10px;
    color: #666666;
    font-weight: bolder;
    border: 1px solid #C2C2C2;
    border-radius: 10px;
}

.viewTableListItem {
    background: linear-gradient(#FFF, #EBCEA6);
    padding: 10px 0;
    margin: 10px;
    color: #573E22;
    font-weight: bolder;
    border: 1px solid #C0853F;
    border-radius: 10px;
}

.viewTableListItem:hover {
    box-shadow: 0 5px 15px -5px #000000;
    transition: all 200ms ease-in-out;
}

.viewTableListItem:active {
    background: linear-gradient(#EBCEA6, #E8A451);
}

.viewTableRow:hover {
    background: #E6E6E6;
    transition: all 100ms ease-in-out;
}

.viewTableRow:active {
    background: #CCCCCC;
}

.SwmTableRowDescartado:hover {
    background-color: #FFB3B3;
}

.nohover:hover{
    background: #ffffff;
}
