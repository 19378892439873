.itemSection {
    height: 100%;
    overflow: auto;
}

.itemSection > div { /*Esto permite "encerrar el contenido dentro de <section>"*/
    height: 500px;
    display: flex;
    flex-direction: column;
}

.descartadoColor {
    background-color: #ffe6e6;
}

.descartadoBanner {
    margin: 10px;
    padding: 10px 0;
    color: #FFF;
    background-color: #F33;
    text-align: center;
    font-weight: bold;
}

.itemTopRow {
    display: flex;
}

.itemImageContainer {
    margin: 10px;
    flex: 1;
}

.itemInfoContainer {
    margin: 10px;
    flex: 1;
}

.itemDatosTable {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
}

.itemDatosLeft,  .itemDatosRight{
    margin: 0 5px;
}

.itemDatosLeft {
    width: 35%;
    font-weight: bold;
}

.itemImage {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.flex2 {
    flex: 2;
}

.swmButtonContainer {
    margin-left: 25px;
    display: flex;
    flex-direction: row;
}
