.headerContainer {
    width: 100%;
    background: #C0853F;
    flex: 0 0 100px;
    display: flex;
    align-items: center;
}

.headerContainer > h2 {
    margin: unset;
    padding: 10px;
    color: #FFFFFF;
}

@media screen and (max-width: 640px) {
    .headerContainer {
        flex: 0 0 50px;
    }
}
