/*@import url("./src/components/error404/error404.module.css");*/
.qrcodeParentContainer {
    width: 80%;
    text-align: center;
}

.qrcodeButtonsContainer {
    margin-top: 1rem;
    padding: 0.5rem;
    background: #FFF;
    border-radius: 10px;
}

@media screen and (min-width: 768px) {
    .qrcodeParentContainer {
        max-width: 598px;
    }
}